import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from 'structure/layout'
import SEO from 'structure/seo'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title='Home' />

    <Container>
      <Img fixed={data.file.childImageSharp.fixed} />
      <P>queroser.ninja</P>
    </Container>
  </Layout>
)


const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font: 700 30px sans-serif;
  justify-content: center;
  height: 100%;
`

// const Img = styled.img`
//   width: 250px;
// `

const P = styled.p`
  margin: 0 0 80px;
`

export const query = graphql`
  query {
    file(relativePath: { eq: "ninja-star-transparent.png" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IndexPage
